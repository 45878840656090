<template>
  <li class="text-base lg:text-xl transition duration-300 ease-in-out hover:text-redAccent hover:[&::marker]:redAccent">
    <slot/>
  </li>
</template>

<script>
export default {
  name: "HomepageLi",
}
</script>
