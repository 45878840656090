<template>
  <t-card variant="homepageTitle">
    <div class="flex flex-col w-full justify-center items-center text-2xl lg:text-5xl font-helvNeu text-redAccentLight mx-auto"
      :class="[
        narrowTopMargin?'pt-8 lg:pt-12':'pt-12 lg:pt-24',
        narrowBottomMargin?'pb-8 lg:pb-12':'pb-12 lg:pb-24'
      ]"
    >
      <div>
        <slot name="title1"/>
      </div>
      <div>
      <slot name="title2"/>
      </div>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "HomepageSelectTitle",
  props:{
    narrowTopMargin:Boolean,
    narrowBottomMargin:Boolean,
  }
}
</script>

